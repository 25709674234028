<template>
    <v-row class="text-left">
        <v-col :cols="6">
            <v-btn
                color="primary"
                width="270"
                :outlined="!dataSourceTypeSelected || dataSourceTypeSelected === 'matomo'"
                @click="connectAnalytics()"
                :disabled="disableSelectAnalytics"
            >
                <img src="@/assets/images/google_analytics_logo_mini.png" alt="Google Analytics" width="20" class="mr-3">
                {{ $t('component_wizardConnectedAnalytics_connect_analytics') }}
            </v-btn>
            <analytics-display-error ref="analyticsErrorMessage" v-if="analyticsError && dataSourceTypeSelected === 'google'"/>
        </v-col>
        <v-col :cols="6" class="text-right">
            <v-btn
                color="primary"
                width="270"
                :outlined="!dataSourceTypeSelected || dataSourceTypeSelected === 'google'"
                @click="connectMatomo()"
                :disabled="disableSelectAnalytics"
            >
                <img src="@/assets/images/matomo_logo_mini.png" alt="Matomo" width="20" class="mr-3">
                {{ $t('component_wizardConnectedAnalytics_connect_matomo') }}
            </v-btn>
        </v-col>

        <v-col cols="12" class="pr-0">
            <div v-if="dataSourceTypeSelected === 'google'">
                <v-select
                    :items="displayedAnalyticsAccountList"
                    v-model="selectedAnalyticsAccount"
                    :label="$t('component_wizardConnectedAnalytics_selectAnalyticsAccount')"
                    solo
                    hide-details
                    return-object
                    :disabled="disableSelectAnalytics"
                    v-if="analyticsAccountList.length >0"
                />
                <div class="text-center mt-3" style="height: 26px">
                    <v-chip color="#FFEBEE" small v-if="googleErrorNoSelect"><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_errorNoSelectAnalyticsAccount') }}</span></v-chip>
                </div>
            </div>

            <div v-if="dataSourceTypeSelected === 'matomo'" class="my-6">
                <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="matomoUrl"
                            :placeholder="$t('component_wizardConnectedAnalytics_matomoUrl')"
                            dense
                            hide-details
                            solo
                            @blur="cleanUrl"
                            :disabled="disableSelectAnalytics"
                            :class="`${matomoErrorUrl ? 'matomo-error-check': ''}`"
                        />
                    </v-col>
                    <v-col cols="3" class="text-center">
                        <div v-if="matomoErrorUrl">
                            <v-chip color="#FFEBEE" small><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_matomoErrorUrl') }}</span></v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="matomoToken"
                            :placeholder="$t('component_wizardConnectedAnalytics_matomoToken')"
                            dense
                            hide-details
                            solo
                            :disabled="disableSelectAnalytics"
                            :class="`${matomoErrorToken ? 'matomo-error-check': ''}`"
                        />
                    </v-col>
                    <v-col cols="3" class="text-center">
                        <div v-if="matomoErrorToken">
                            <v-chip color="#FFEBEE" small><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_matomoErrorToken') }}</span></v-chip>
                        </div>
                        <a href="https://one.yooda.com/fr/help/getting-started/connect-matomo" target="_blank" class="caption underline">{{ $t('component_wizardConnectedAnalytics_matomoHelpToken') }}</a>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="3"></v-col>
                    <v-col cols="6">
                        <v-text-field
                            v-model="matomoId"
                            :placeholder="$t('component_wizardConnectedAnalytics_matomoId')"
                            dense
                            type="number"
                            hide-details
                            solo
                            :disabled="disableSelectAnalytics"
                            :class="`${matomoErrorId ? 'matomo-error-check': ''}`"
                        />
                    </v-col>
                    <v-col cols="3" class="text-center">
                        <div v-if="matomoErrorId">
                            <v-chip color="#FFEBEE" small><span style="color: #F44336">{{ $t('component_wizardConnectedAnalytics_matomoErrorId') }}</span></v-chip>
                        </div>
                        <a href="https://one.yooda.com/fr/help/getting-started/connect-matomo" target="_blank" class="caption underline">{{ $t('component_wizardConnectedAnalytics_matomoHelpId') }}</a>
                    </v-col>
                </v-row>
            </div>
        </v-col>

        <v-col cols="12">
            <v-row>
                <v-col cols="6">
                    <v-btn
                        color="primary"
                        outlined
                        width="270"
                        @click="stepProgressWithoutAnalytics()"
                        :disabled="disableSelectAnalytics"
                    >
                        {{ $t('component_wizardConnectedAnalytics_skip_analytics') }}
                    </v-btn>
                </v-col>

                <v-col cols="6">
                    <v-btn
                        color="primary"
                        width="270"
                        @click="saveAnalyticsConfig()"
                        :disabled="disableSelectAnalytics"
                        v-if="dataSourceTypeSelected"
                    >
                        {{ $t('component_wizardConnectedAnalytics_matomoSave') }}
                    </v-btn>
                </v-col>
            </v-row>

        </v-col>

    </v-row>

</template>

<script>
import AnalyticsDisplayError from "@/components/yooda-components/analytics/AnalyticsDisplayError.vue";

import axios from "axios";
const axiosForGoogleAuthenticator = axios.create({
    baseURL: `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}`,
})

export default {
    name: "WizardConnectAnalytics",
    data(){
        return{
            disableSelectAnalytics:false,

            dataSourceTypeSelected:null,
            analyticsAccountList:[],
            selectedAnalyticsAccount:null,
            analyticsError:false,
            googleToken:null,
            googleIdentifier:null,
            googleErrorNoSelect:false,
            matomoUrl:null,
            matomoToken:null,
            matomoId:null,
            matomoErrorUrl:false,
            matomoErrorToken:false,
            matomoErrorId:false,
        }
    },
    computed:{
        displayedAnalyticsAccountList(){

            let propertyList = []
            this.analyticsAccountList.forEach((account)=>{
                if(account.properties.length>0){
                    account.properties.forEach((property)=>{
                        if(property.type === 'GA4'){
                            propertyList.push({
                                text:`GA4 - ${property.name}`,
                                value:property.id,
                                data:{
                                    account:{ id:account.id, name:account.name },
                                    property:{ id:property.id, name:property.name },
                                }
                            })
                        }
                        else{
                            property.profiles.forEach((profiles)=>{
                                propertyList.push({
                                    text:`UA - ${profiles.name}`,
                                    value:profiles.id,
                                    data:{
                                        account:{ id:account.id, name:account.name },
                                        property:{ id:property.id, name:property.name },
                                        view:{ id:profiles.id, name:profiles.name },
                                    }
                                })
                            })
                        }
                    })
                }
            })
            return propertyList
        }
    },
    methods:{
        checkToken(refreshToken){

            let promise =  axiosForGoogleAuthenticator.get(`/analytics/account-summaries/${refreshToken}`)
            promise.then((response)=>{
                if(!response || response.data.error){
                    this.analyticsError = true
                    this.$nextTick(()=>{
                        this.$refs.analyticsErrorMessage.displayAuthorizationError()

                        this.scrollToBottomMessage()
                    })
                }
                else{
                    this.googleToken = refreshToken
                    this.googleIdentifier = response.data.user.email
                    this.analyticsAccountList = response.data.accountSummaries
                    this.dataSourceTypeSelected = 'google'
                    this.$router.push(this.$route.path)
                    this.scrollToBottomMessage()
                }
            })
        },
        connectAnalytics(){
            this.dataSourceTypeSelected = 'google'
            if(this.analyticsAccountList.length === 0){
                window.location = `${process.env.VUE_APP_YOODA_ONE_GOOGLE_AUTHENTICATOR_BASE_URI}/authentication?scope-analytics=true&callback-url=${window.location.href+="?restoreProject=true"}`
            }
        },
        connectMatomo(){
            this.dataSourceTypeSelected = 'matomo'
            this.scrollToBottomMessage()
        },
        stepProgressWithoutAnalytics(){
            this.$emit('stepProgress')
        },
        cleanUrl(){
            if(this.matomoUrl){
                if(this.matomoUrl.charAt(this.matomoUrl.length -1) === '/'){
                    this.matomoUrl = this.matomoUrl.slice(0,-1)
                }
            }

        },
        saveAnalyticsConfig(){

            if(this.dataSourceTypeSelected === 'matomo'){
                this.matomoErrorUrl = false
                this.matomoErrorToken = false
                this.matomoErrorId = false


                if(this.matomoUrl && this.matomoToken && this.matomoId){
                    let promise =  axiosForGoogleAuthenticator.get(`/matomo/check-config/url/${this.matomoUrl}/token/${this.matomoToken}/id/${this.matomoId}`)
                    promise.then((response)=>{
                        if(response.data.errorType){
                            if(response.data.errorType === 'BAD_URL'){ this.matomoErrorUrl = true }
                            if(response.data.errorType === 'BAD_TOKEN'){ this.matomoErrorToken = true }
                            if(response.data.errorType === 'BAD_ID'){ this.matomoErrorId = true }
                        }
                        else{

                            this.disableSelectAnalytics = true

                            this.$emit('stepProgress', {
                                type:'analytics',
                                category:'matomo_analytics',
                                identifier:this.matomoUrl,
                                token:this.matomoToken,
                                viewConfiguration: { site: { id:parseInt(this.matomoId) } }
                            })
                        }

                    })
                } else {
                    this.matomoErrorUrl = !this.matomoUrl
                    this.matomoErrorToken = !this.matomoToken
                    this.matomoErrorId = !this.matomoId
                }

            }
            else{

                this.googleErrorNoSelect = false
                if(this.selectedAnalyticsAccount){
                    this.disableSelectAnalytics = true
                    this.$emit('stepProgress', {
                        type:'analytics',
                        category:'google_analytics',
                        identifier:this.googleIdentifier,
                        token:this.googleToken,
                        viewConfiguration:this.selectedAnalyticsAccount.data
                    })
                }else{
                    this.googleErrorNoSelect = true
                }
            }
        },
        scrollToBottomMessage(){
            this.$emit('scrollToBottomMessage')
        }
    },
    components:{
        AnalyticsDisplayError,
    },
    beforeMount() {
        if(this.$route.query.refreshTokenAnalytics){
            this.checkToken(this.$route.query.refreshTokenAnalytics)
        }

    }
}
</script>
<style lang="scss">

</style>
