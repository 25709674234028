<template>
    <div style="padding: 24px 0 18px 0" v-if="showStep">
        <v-row style="margin: 0!important" class="px-10">
            <v-col :cols="1">
                <div class="primary" style="width: 40px; height: 40px; padding-top: 8px; border-radius: 100%; text-align: center">
                    <v-icon color="white" >mdi-face-man</v-icon>
                </div>
            </v-col>
            <v-col :cols="10" :style="{height: `${height}px`}">
                <span>Maitre Yooda</span>
                <v-card>
                    <v-card-text>
                        <vue-typed-js
                            :strings="[message]"
                            :loop="false"
                            :showCursor="false"
                            :typeSpeed="7"
                            :startDelay="300"
                            :contentType="'html'"
                            @onComplete="onCompleteStep"
                            v-if="animate"
                        >
                            <span class="typing" style="font-weight: 500"></span>
                        </vue-typed-js>
                        <span v-html="message.replaceAll('^200','')" v-else/>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col :cols="1">

            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "WizardChatMessage",
    props: {
        showStep: {
            type: Boolean,
            default: false
        },
        message: {
            type: String,
            default: ''
        },
        height:{
            type: Number,
            default: 115
        },
        animate:{
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
        }
    },
    methods:{
        onCompleteStep(){
            this.$emit('completed')
        }
    }
}
</script>

<style scoped>

</style>
