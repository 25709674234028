<template>
    <v-carousel
        cycle
        :interval="10000"
        height="440"
        hide-delimiter-background
        hide-delimiters
    >

        <v-carousel-item>
            <v-card height="380" class="ma-8">
                <v-card-text>
                    <v-row>
                        <v-col :cols="12">
                            <h3 class="text-h5 text-center font-weight-bold">{{ $t('component_wizardCarousel_waitingCompute_1_title') }}</h3>
                        </v-col>
                        <v-col :cols="4">
                            <img src="@/assets/images/wizzard/1a-visibilitВ-globale.png" width="220px" class="mt-8">
                        </v-col>
                        <v-col :cols="8">
                            <p v-html="$t('component_wizardCarousel_waitingCompute_1_text')" class="subtitle-1 mt-4 mb-0"/>

                            <img src="@/assets/images/wizzard/1b-visibilitВ-segments.png" width="420px" class="mt-2">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-carousel-item>

        <v-carousel-item>
            <v-card height="380" class="ma-8">
                <v-card-text>
                    <v-row>
                        <v-col :cols="12">
                            <h3 class="text-h5 text-center font-weight-bold">{{ $t('component_wizardCarousel_waitingCompute_2_title') }}</h3>
                        </v-col>
                        <v-col :cols="12" class="px-6">
                            <p v-html="$t('component_wizardCarousel_waitingCompute_2_text')" class="subtitle-1 mt-4"/>
                        </v-col>
                        <v-col :cols="12" class="text-center">
                            <img src="@/assets/images/wizzard/2-Shopping.png" width="640px" >
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-carousel-item>


        <v-carousel-item>
            <v-card height="380" class="ma-8">
                <v-card-text>
                    <v-row>
                        <v-col :cols="12">
                            <h3 class="text-h5 text-center font-weight-bold">{{ $t('component_wizardCarousel_waitingCompute_3_title') }}</h3>
                        </v-col>
                        <v-col :cols="8">
                            <img src="@/assets/images/wizzard/3-prioritВs.png" width="420px" class="mt-6">
                        </v-col>
                        <v-col :cols="4" class="pr-8">
                            <p v-html="$t('component_wizardCarousel_waitingCompute_3_text')" class="subtitle-1 mt-12"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-carousel-item>

        <v-carousel-item>
            <v-card height="380" class="ma-8">
                <v-card-text>
                    <v-row>
                        <v-col :cols="12">
                            <h3 class="text-h5 text-center font-weight-bold">{{ $t('component_wizardCarousel_waitingCompute_4_title') }}</h3>
                        </v-col>
                        <v-col :cols="12" class="px-6 mt-4">
                            <p v-html="$t('component_wizardCarousel_waitingCompute_4_text')" class="subtitle-1"/>
                        </v-col>
                        <v-col :cols="12" class="text-center">
                            <img src="@/assets/images/wizzard/4-Positions.png" width="640px" class="mt-0">
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-carousel-item>

    </v-carousel>
</template>

<script>
export default {
    name: "WizardCarousel"
}
</script>

<style scoped>

</style>
