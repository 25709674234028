<template>
    <div id="chat-wizard-container" ref="chatWizard" class="v-application">
        <div v-show="!creationWaiting">
            <div class="text-center mt-6">
                <img src="@/assets/images/logo-yooda-one-big.png" style="width: 300px;" alt="YOODA One" >
            </div>

            <wizard-chat-message
                :show-step="showStep >=1"
                :message="$t('view_firstProject_wizardMessage_welcome')"
                :animate="animateMessage"
                @completed="validFirstStep"
            />

            <v-row class="chat-wizard-title-container my-6" v-if="showStep >=2">
                <v-col :cols="12" class="px-8 pb-0" style="background-color: #f7f6f6;">
                    <v-card>
                        <v-card-text>
                            <h3 class="text-h4 font-weight-bold primary--text mb-6">{{ $t('view_firstProject_wizardMessage_title') }}</h3>
                            <v-progress-linear :value="setupProgress" height="8" style="border-radius: 4px"/>
                            <div class="text-right mt-4">
                                <span>{{ $t(`view_firstProject_wizardMessage_remaining_${setupProgress}`) }}</span>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>


            <wizard-chat-message
                :show-step="showStep >=3"
                :message="$t('view_firstProject_wizardMessage_firstQuestion')"
                :height="150"
                :animate="animateMessage"
                @completed="stepProgress"
            />


            <div v-if="showStep >=4">
                <div v-for="n in 5" :key="n">
                    <v-row style="margin: 0!important" class="px-10" v-if="n === 1 || productService[n-2] || productService.length >= n">
                        <v-col :cols="2"></v-col>
                        <v-col :cols="10">
                            <v-text-field
                                v-model="productService[n-1]"
                                :placeholder="$t('view_firstProject_wizardMessage_product_placeholder')"
                                dense
                                hide-details
                                solo
                                @blur="clearProductService"
                            />
                        </v-col>
                    </v-row>
                </div>
                <p class="red--text text--lighten-1 text-right pr-14" v-if="validErrorProduct">{{ $t('view_firstProject_wizard_validationError_products') }}</p>
                <v-row style="margin: 0!important" class="px-10" >
                    <v-col :cols="2"></v-col>
                    <v-col :cols="10" class="text-right">
                        <v-btn
                            color="primary"
                            :disabled="productService.length < 2"
                            outlined
                            @click="validProduct"
                        >
                            {{ $t('view_firstProject_wizardMessage_secondQuestion_valid') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </div>


            <wizard-chat-message
                :show-step="showStep >=5"
                :message="$t('view_firstProject_wizardMessage_thirdQuestion')"
                :height="140"
                :animate="animateMessage"
                @completed="stepProgress"
            />


            <div v-if="showStep >=6">
                <v-row style="margin: 0!important" class="px-10" >
                    <v-col :cols="2"></v-col>
                    <v-col :cols="10">
                        <v-text-field
                            v-model="websiteUrl"
                            :placeholder="$t('view_firstProject_wizardMessage_website_placeholder')"
                            dense
                            hide-details
                            solo
                            @blur="cleanWebsiteUrl"
                            v-on:keyup.enter="cleanWebsiteUrl"
                            v-on:keyup="cleanWebsiteUrl"
                        />
                    </v-col>
                </v-row>
                <p class="red--text text--lighten-1 text-right pr-14" v-if="validErrorWebsite">{{ $t('view_firstProject_wizard_validationError_website') }}</p>
            </div>

            <wizard-chat-message
                :show-step="showStep >=7"
                :message="$t('view_firstProject_wizardMessage_fourthQuestion')"
                :height="140"
                :animate="animateMessage"
                @completed="stepProgress"
            />


            <div v-if="showStep >=8">
                <v-row style="margin: 0!important" class="px-10" >
                    <v-col :cols="2"></v-col>
                    <v-col :cols="10">
                        <v-text-field
                            v-model="competitorUrl"
                            :placeholder="$t('view_firstProject_wizardMessage_competitor_placeholder')"
                            dense
                            hide-details
                            solo
                            @blur="cleanWebsiteUrl"
                            v-on:keyup.enter="cleanWebsiteUrl"
                            v-on:keyup="cleanWebsiteUrl"
                        />
                    </v-col>
                    <v-col :cols="12" class="text-right">
                        <p class="mb-0">{{ $t('view_firstProject_wizardMessage_competitor_skipLabel') }}</p>
                        <span class="primary--text underline cursor-pointer body-2" @click="skipCompetitor">{{ $t('view_firstProject_wizardMessage_competitor_skipLink') }}</span>

                    </v-col>
                </v-row>
                <p class="red--text text--lighten-1 text-right pr-14" v-if="validErrorRival">{{ $t('view_firstProject_wizard_validationError_rival') }}</p>
            </div>

            <wizard-chat-message
                :show-step="showStep >=9"
                :message="$t('view_firstProject_wizardMessage_fifthQuestion')"
                :animate="animateMessage"
                @completed="stepProgress"
            />

            <div v-if="showStep >=10">
                <v-row style="margin: 0!important" class="px-10">
                    <v-col :cols="2">
                    </v-col>
                    <v-col :cols="10">
                        <v-row>
                            <v-col :cols="4" v-for="(country, index) in countryList" :key="index">
                                <v-btn
                                    color="primary"
                                    width="100%"
                                    :outlined="!selectedCountry || (selectedCountry.countryCode !== country.countryCode)"
                                    @click="selectCountry(country)"
                                >
                                    {{ $t(`common_countryName_for_searchEngine_${country.name}`) }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </div>


            <div v-if="showStep >=11">
                <v-row style="margin: 0!important" class="px-10 pt-2">
                    <v-col :cols="2">
                    </v-col>
                    <v-col :cols="10">
                        <v-select
                            :items="languages"
                            v-model="selectedLanguage"
                            :label="$t('view_firstProject_wizardMessage_selectLanguage')"
                            solo
                            @change="selectLanguage"
                        >
                            <template v-slot:selection="{ item, index }">
                                {{ $t(`common_language_label_${item.code}`) }}
                            </template>
                            <template v-slot:item="{ active, item, attrs, on }">
                                <v-list-item v-on="on" v-bind="attrs" >
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <span>{{ $t(`common_language_label_${item.code}`) }}</span>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>

                        </v-select>
                    </v-col>
                </v-row>

                <p class="red--text text--lighten-1 text-right pr-14" v-if="validErrorLanguage">{{ $t('view_firstProject_wizard_validationError_language') }}</p>
            </div>

            <wizard-chat-message
                :show-step="showStep >=12"
                :message="$t('view_firstProject_wizardMessage_sixQuestion')"
                :animate="animateMessage"
                :height="165"
                @completed="stepProgress"
            />


            <div v-if="showStep >=13">
                <v-row style="margin: 0!important" class="px-10 pt-2">
                    <v-col :cols="2">
                    </v-col>
                    <v-col :cols="10" class="text-right">
                        <wizard-connect-analytics
                            @stepProgress="stepProgress"
                            @scrollToBottomMessage="scrollToBottomMessage"
                        />
                    </v-col>
                </v-row>

                <wizard-chat-message
                    :show-step="showStep >=14"
                    :message="$t('view_firstProject_wizardMessage_confirmCreation')"
                    :height="140"
                    @completed="stepProgress"
                />


                <div v-if="showStep >=15">
                    <v-row style="margin: 0!important" class="px-10 pt-2">
                        <v-col :cols="2"></v-col>
                        <v-col :cols="10" class="text-right">
                            <v-btn @click="createProject" :disabled="alreadyCreated" color="primary">{{ $t('view_firstProject_wizardMessage_confirmCreation_btn') }}</v-btn>
                        </v-col>
                    </v-row>
                </div>

            </div>
        </div>


        <v-row class="chat-wizard-title-container my-6" v-if="showStep >=16">
            <v-col :cols="12" class="px-8 pb-0" style="background-color: #f7f6f6;">
                <v-card>
                    <v-card-text>
                        <h3 class="text-h4 font-weight-bold primary--text mb-6">{{ $t('view_firstProject_waitingCompute_title') }}</h3>
                        <v-progress-linear :value="computeProgress" height="8" style="border-radius: 4px"/>
                        <div class="text-right mt-4">
                            <span>{{ $t(`view_firstProject_waitingCompute_remaining_${computeStep}`) }}</span>
                        </div>
                        <p class="text-center mb-0">{{ `${$t('view_firstProject_waitingCompute_step')} ${computeStep}/7` }}</p>
                        <p class="text-center primary--text mb-0 font-weight-medium">{{ $t(`view_firstProject_waitingCompute_step${computeStep}`) }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <wizard-chat-message
            :show-step="showStep >=16"
            :message="$t('view_firstProject_waitingCompute_advice')"
            :height="165"
            @completed="stepProgress"
        />

        <div class="px-0" style="margin-top: -64px" v-if="showStep >=17">
            <wizard-carousel/>
        </div>

    </div>
</template>

<script>

import WizardChatMessage from "@/components/yooda-components/projectWizzard/WizardChatMessage.vue";
import apiCalls from "@/api/axios/configuration/project";
import WizardCarousel from "@/components/yooda-components/projectWizzard/WizardCarousel.vue";
import WizardConnectAnalytics from "@/components/yooda-components/projectWizzard/WizardConnectAnalytics.vue";
import analyticsApiCalls from "@/api/axios/configuration/analytics";

export default {
    name: "ProjectCreationWizard",
    data() {
        return {
            animateMessage:true,
            creationWaiting:false,
            validErrorProduct:false,
            validErrorWebsite:false,
            validErrorRival:false,
            validErrorLanguage:false,
            showStep:0,
            checkWebsiteHandlerId:0,
            setupProgress:0,
            computeProgress:0,
            computeStep:1,
            productService:[],
            websiteUrl:null,
            competitorUrl:null,
            countryList:[],
            selectedCountry:null,
            languages:[],
            selectedLanguage:null,
            selectedDefaultGeolocation:null,

            analyticsToAdd:null,

            projectCreationId:0,
            alreadyCreated:false
        }
    },
    components: {
        WizardChatMessage,
        WizardConnectAnalytics,
        WizardCarousel,
    },
    watch:{
        productService(){
            this.validErrorProduct = false
        }
    },
    methods:{
       async addDataSource() {
            return analyticsApiCalls.addDataSource(this.$route.params.subscriptionId,{
                category: this.analyticsToAdd.category,
                identifier: this.analyticsToAdd.identifier,
                token: this.analyticsToAdd.token
            })

        },
        clearProductService(){
            let cleanedProductService = []
            this.productService.forEach((product)=>{
                cleanedProductService.push(this.cleanKeywordInput(product))
            })
            this.productService = cleanedProductService
        },
        async createProject(){
            this.validErrorProduct = false
            this.validErrorWebsite = false
            this.validErrorLanguage = false

            let productService = this.productService.filter(product => product.trim() !== '');

            let project = {
                url:this.getDomainNameFromString(this.websiteUrl),
                name:this.getDomainNameFromString(this.websiteUrl),
                keywordGroups: productService.map((product)=>{
                    return {
                        name:product,
                        keywords:[{string:product}]
                    }
                }),
                keywords:productService.map((product)=>{return {string:product}}),
                searchGeolocations:this.selectedDefaultGeolocation
            }



            if(this.selectedCountry){ project.searchEngine = {id:this.selectedCountry.id} }
            if(this.selectedLanguage){ project.language = {id:this.selectedLanguage.id} }



            if(project.keywordGroups.length < 2){
                this.validErrorProduct = true
                this.$refs.chatWizard.scrollTo({top: 100, behavior: 'smooth'})
                return false
            }

            if(!project.url){
                this.validErrorWebsite = true
                this.$refs.chatWizard.scrollTo({top: 400, behavior: 'smooth'})
                return false
            }

            if(this.competitorUrl){
                let rivalUrl = this.getDomainNameFromString(this.competitorUrl)
                if(!rivalUrl){
                    this.validErrorRival = true
                    this.$refs.chatWizard.scrollTo({top: 600, behavior: 'smooth'})
                    return false
                }else{
                    project.rivals = [{url:rivalUrl}]

                    if(project.rivals[0].url && (project.url.trim() === project.rivals[0].url.trim())){
                        this.validErrorRival = true
                        this.$refs.chatWizard.scrollTo({top: 600, behavior: 'smooth'})
                        return false
                    }
                }

            }

            if(!project.language){
                this.validErrorLanguage = true
                this.$refs.chatWizard.scrollTo({top: 1100, behavior: 'smooth'})
                return false
            }


            this.alreadyCreated = true
            if(this.analyticsToAdd){
                project.analyticsViewConfiguration = this.analyticsToAdd.viewConfiguration
                await this.addDataSource()
                .then((response)=>{
                    project.analyticsDataSource = response.content.id
                })
            }


            apiCalls.getSearchEngineGeolocs(project.searchEngine.id).then((res)=>{
                project.searchGeolocations = [{id:res.content.find(el => el.targetType === "country").id}]

                let promise = apiCalls.createProject(this.$route.params.subscriptionId, project, true)
                promise.then((response) => {
                    let creationJobId = response.content.id
                    this.stepProgress()
                    this.creationWaiting = true
                    this.progressWaitingCompute()
                    this.checkCreationDone(creationJobId)
                })
            })



        },

        progressWaitingCompute(){
            setInterval(()=>{
                if(this.computeProgress < 100){ this.computeProgress++ }
                if(this.computeProgress>3 && this.computeStep===1){ this.computeStep ++ }
                if(this.computeProgress>34 && this.computeStep===2){ this.computeStep ++ }
                if(this.computeProgress>51 && this.computeStep===3){ this.computeStep ++ }
                if(this.computeProgress>68 && this.computeStep===4){ this.computeStep ++ }
                if(this.computeProgress>85 && this.computeStep===5){ this.computeStep ++ }
                if(this.computeProgress>95 && this.computeStep===6){ this.computeStep ++ }
            },3000)
        },

        checkCreationDone(creationJobId){
            setInterval(()=>{

                if(this.projectCreationId === 0){
                    let promise = apiCalls.getCreateProjectStatus(creationJobId)
                    promise.then((response) => {
                        if(response.content.projectId){
                            this.projectCreationId = response.content.projectId
                        }
                    })
                }
                else{
                    let promise = apiCalls.getProjectInfo(this.projectCreationId)
                    promise.then((response) => {
                        if(response.content.reports.length>0){
                            if(response.content.reports[response.content.reports.length-1].complete){
                                window.location.href = '/subscriptions/'+this.$route.params.subscriptionId+'/projects/'+this.projectCreationId+'/dashboard-recommendations'
                            }
                        }
                    })
                }
            },10000)
        },
        validFirstStep(){
            window.setTimeout(()=>{ this.stepProgress() },1500)
        },
        scrollToBottomMessage(){
            window.setTimeout(()=>{
                this.$refs.chatWizard.scrollTo({top: this.$refs.chatWizard.scrollHeight, behavior: 'smooth'})
            },150)
        },

        stepProgress(payload) {

            let progress = true
            if(payload){
                if(payload.type === 'analytics'){
                    this.analyticsToAdd = payload
                    progress = this.showStep<14
                }

            }

            if(progress){
                this.showStep ++
                this.scrollToBottomMessage()

                switch (this.showStep){
                    case 2: window.setTimeout(()=>{ this.stepProgress() },2000); break;
                    case 5: this.setupProgress = 20; break;
                    case 7: this.setupProgress = 40; break;
                    case 9: this.setupProgress = 60; break;
                    case 12:
                    case 13: this.setupProgress = 80; break;
                    case 14: this.setupProgress = 100; break;
                }

                if(this.showStep<15){
                    this.saveTmpProjectToLocal()
                }
            }


        },
        saveTmpProjectToLocal(){
            let tmpProject = {
                websiteUrl:this.websiteUrl,
                competitorUrl:this.competitorUrl,
                productService:this.productService,
                selectedCountry:this.selectedCountry,
                selectedLanguage:this.selectedLanguage,
            }
            localStorage.setItem('firstProjectTmp', JSON.stringify(tmpProject))
        },

        validProduct(){
            if(this.showStep<=5){ this.stepProgress() }
        },

        skipCompetitor(){
            this.competitorUrl = null
            if(this.showStep<=9){ this.stepProgress() }
        },

        cleanWebsiteUrl(){
            this.validErrorWebsite = false
            this.validErrorRival = false
            clearTimeout(this.checkWebsiteHandlerId)
            this.checkWebsiteHandlerId = setTimeout(()=>{
                if(this.getDomainNameFromString(this.websiteUrl)){
                    this.websiteUrl = this.getDomainNameFromString(this.websiteUrl)
                    if(this.showStep<=6){
                        this.stepProgress()
                    }
                }
                if(this.getDomainNameFromString(this.competitorUrl)){
                    this.competitorUrl = this.getDomainNameFromString(this.competitorUrl)
                    if(this.showStep<=8){
                        this.stepProgress()
                    }
                }
            }, 1200);
        },

        selectCountry(country){
            this.selectedCountry = country
            this.selectedLanguage = null
            if(country){
                this.countryList.forEach((data)=>{
                    if(data.countryCode === country.countryCode){
                        this.languages = data.languages
                        if(data.defaultGeolocation.length>0){
                            this.selectedDefaultGeolocation = [{id:data.defaultGeolocation[0].id}]
                        }
                        if(this.languages.length === 1){
                            this.selectedLanguage = this.languages[0]
                            if(this.showStep<=10){
                                this.showStep = 11
                                this.selectLanguage()
                            }
                        }
                    }
                })
            }


            if(this.showStep<=10){
                this.stepProgress()
            }else{
                this.saveTmpProjectToLocal()
            }
        },

        selectLanguage(){
            this.validErrorLanguage = false
            if(this.showStep<=12){
                this.stepProgress()
            }else{
                this.saveTmpProjectToLocal()
            }
        },

        initModeCreate(){
            if(this.$route.query.restoreProject){
                this.animateMessage = false

            }
            else{
                window.setTimeout(()=>{ this.stepProgress() },500)
            }

            apiCalls.getSearchEngines().then((res)=>{
                this.countryList = res.content.map((data)=>{
                    return {
                        countryCode:data.countryCode,
                        languages: data.languages,
                        name: data.name,
                        id: data.id,
                        defaultGeolocation:''
                    }
                })

                if(this.$route.query.restoreProject){
                    let tmpProject = JSON.parse(localStorage.getItem('firstProjectTmp'))
                    if(tmpProject){
                        this.websiteUrl = tmpProject.websiteUrl
                        this.competitorUrl = tmpProject.competitorUrl
                        this.productService = tmpProject.productService
                        this.selectedCountry = tmpProject.selectedCountry
                        this.selectCountry(this.selectedCountry)
                        this.selectedLanguage = tmpProject.selectedLanguage
                    }

                    this.showStep = 12
                    this.stepProgress()
                }

            })
        },

        initModeWaiting(jobId){
            this.showStep = 15
            this.stepProgress()
            this.creationWaiting = true

            this.progressWaitingCompute()
            this.checkCreationDone(jobId)
        }

    },
    beforeMount() {
        localStorage.setItem('first-project-wizard','true')

        let promise = apiCalls.getCreateProjectBySubscription(this.$route.params.subscriptionId)
        promise.then((response) => {

            if(response.content.length === 0){
                this.initModeCreate()
            }
            else{
                if(response.content.filter((project)=>{ return project.status !== 'done' }).length>0){
                    this.initModeWaiting(response.content[response.content.length-1].id)
                }else{
                    let projectPromise = apiCalls.getProjectInfo(response.content[response.content.length-1].projectId)
                    projectPromise.then((projectResponse) => {
                        if(projectResponse.content.reports.length === 0 || !projectResponse.content.reports[projectResponse.content.reports.length-1].complete){
                            this.initModeWaiting(response.content[response.content.length-1].id)
                        }
                        else{
                            this.initModeCreate()
                        }

                    }).catch(()=>{
                        this.initModeCreate()
                    })
                }
            }
        })
    },


}
</script>


<style lang="scss">

.first-project-page{
    #chat-wizard-container{
        max-width: 800px;
        min-width: 380px;
        margin: auto;
        padding: 0 14px 48px 14px !important;
        background-color: #f7f6f6;
        border: 1px solid #d7d7d7;
        border-radius: 8px;
        height: 95vh;
        position: relative;
        top: 14px;
        overflow: auto;
        &::-webkit-scrollbar { width: 6px; }

        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px #777777;
            border-radius: 6px;
        }
        &::-webkit-scrollbar-thumb {
            background: #adadad;
            border-radius: 6px;
        }

        .chat-wizard-title-container{
            position: sticky;
            top: 0;
            z-index:10;
        }

        .row{ animation: zoomIn 0.3s; }
        .v-btn--outlined{ background-color: #fff; }

        .col{
            .v-btn{opacity: 0;}
            &:nth-of-type(1){ .v-btn{ animation: bounceIn 0.5s 0.1s both; } }
            &:nth-of-type(2){ .v-btn{ animation: bounceIn 0.5s 0.3s both; } }
            &:nth-of-type(3){ .v-btn{ animation: bounceIn 0.5s 0.5s both; } }
            &:nth-of-type(4){ .v-btn{ animation: bounceIn 0.5s 0.7s both; } }
            &:nth-of-type(5){ .v-btn{ animation: bounceIn 0.5s 0.9s both; } }
            &:nth-of-type(6){ .v-btn{ animation: bounceIn 0.5s 1.1s both; } }
            &:nth-of-type(7){ .v-btn{ animation: bounceIn 0.5s 1.3s both; } }
            &:nth-of-type(8){ .v-btn{ animation: bounceIn 0.5s 1.5s both; } }
            &:nth-of-type(9){ .v-btn{ animation: bounceIn 0.5s 1.7s both; } }
            &:nth-of-type(10){ .v-btn{ animation: bounceIn 0.5s 1.9s both; } }
            &:nth-of-type(11){ .v-btn{ animation: bounceIn 0.5s 2.1s both; } }
            &:nth-of-type(12){ .v-btn{ animation: bounceIn 0.5s 2.3s both; } }
        }
    }
    .v-menu__content{
        padding: 0 !important;
        &.v-menu__content-tooltip{ padding: 6px 12px !important; }
        .v-select-list{
            padding: 0 !important;
            .v-list-item{
                &:before{ background-color: #fff; }
                &:hover{
                    &:before{ background-color: #212121; }
                }
            }
        }
    }
    .v-window__next, .v-window__prev{ background-color: #2196f3; }
}



@keyframes bounceIn {
    from,20%,40%,60%,80%,to {
        -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    0% { opacity: 0; transform: scale3d(0.3, 0.3, 0.3); }
    20% { transform: scale3d(1.1, 1.1, 1.1); }
    40% { transform: scale3d(0.9, 0.9, 0.9); }
    60% { opacity: 1; transform: scale3d(1.03, 1.03, 1.03); }
    80% { transform: scale3d(0.97, 0.97, 0.97); }
    to { opacity: 1; transform: scale3d(1, 1, 1); }
}

@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }
    80% { opacity: 1; }
}

</style>
