<template>

    <project-creation-wizard/>

</template>


<script>
import ProjectCreationWizard from "@/components/yooda-components/projectWizzard/ProjectCreationWizard.vue";

export default {
   components:{
       ProjectCreationWizard
   },
    beforeMount() {

        this.$emit('setAppClasses', 'first-project-page')
    }
}

</script>
